@import '../node_modules/tippy.js/dist/tippy.css';
@import '../node_modules/tippy.js/themes/light.css';
@import '../node_modules/tippy.js/animations/scale.css';
@import '../node_modules/highlight.js/styles/vs2015.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;


pre code.hljs {
    display: block;
    overflow-x: auto;
    padding: 0em;
}

code.hljs {
    padding: 0px 0px
}